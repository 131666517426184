.navbar-footer {
display: none;
}

@media only screen and (max-width: 1000px) {
.navbar-footer {
display: flex;
position: fixed;
bottom: 0;
width: 100%;
justify-content: center;
height: 84px;
background-color: var(--bg-color-blue);
color: var(--fc-secondary);
z-index: 1000;
box-shadow: 0px -5px 5px #00006685;
}

.navbar-footer>div>button {
    display: inline-grid;
    justify-items: center;
}

.navbar-footer>div>button>svg {
color: var(--fc-secondary);
}

.navbar-footer>div>button>div {
    color: var(--fc-secondary);
    }
}