.map-select > h3 {
  padding-bottom: 5px;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}

.customcss-muidropdown {
  box-shadow: 2px 2px rgba(0, 0, 0, 0.15);
}

ul {
  max-height: 300px;
}