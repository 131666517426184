@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500;600;900&family=Oswald:wght@600&family=PT+Sans:ital,wght@0,700;1,700&family=Kanit:ital,wght@1,500&display=swap');

:root {
  --bg-color-blue: #000066;
  --bg-color-gray:#E6E6EB;
  --bg-color-white:#F5F5FA;
  --fc-secondary: #F5F5FA;
  --bg-secondary: #ff9100;
}

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color-blue);
  color: #1F171F;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 44px;
}

h2 {
  font-size: 42px;
  font-family: 'Kanit', sans-serif;
  text-shadow: 2px 2px #1F171F;
  color: var(--fc-secondary)
}

h3 {
  font-size: 30px;
  font-family: "Nunito";
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: currentcolor;
  text-decoration-color: var(--bg-secondary);
}

h4 {
  font-size: 24px;
  font-family: "Nunito";
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: currentcolor;
  text-decoration-color: var(--bg-secondary);
}

@media(max-width: 850px) {
  h2 {
    font-size: 26px;
  }
}

@media(max-width: 400px) {
  h3 {
    font-size: 24px;
  }
}