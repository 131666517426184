header {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100px;
    background-color: var(--bg-color-blue);
    color: var(--fc-secondary);
    z-index: 1000;
    box-shadow: 0px 5px 5px #00006685;
}

.header-title {
    width: 220px;
    padding: 16px;
    padding-left: 14%;
    padding-top: 32px;
}

.navbar-form {
    display: flex;
    width: 520px;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
    padding-right: 15%;
}

.navbar-form>p {
    font-size: 1.5em;
    font-family: "PT Sans";
}

.navbar-form>*:hover {
    cursor: pointer;
}

.navbar-return {
    display: flex;
    width: 520px;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
    padding-right: 15%;
}

.navbar-return>p {
    font-size: 1.5em;
    font-family: "PT Sans";
}

.navbar-return>*:hover {
    cursor: pointer;
}

@media only screen and (max-width: 1000px) {
    header {
        position: absolute;
        justify-content: center;
    }

    .header-title {
        width: 240px;
        padding: 0;
        padding-left: 0px;
        padding-top: 20px;
    }

    .navbar-form {
        display: none;
    }

    .navbar-flex {
        position: absolute;
        right: 7%;
        bottom: 32px;
        width: auto;
    }

    .navbar-return {
        display: none;
    }
}

@media(max-width: 460px) {
    header {
        justify-content: flex-start;
    }

    .header-title {
        padding-left: 10px;
    }
}