.fab-phone-extended {
    position: fixed;
    z-index: 1001;
    right: 34%;
    top: 26px;
}

.fab-phone-extended>button>a>svg {
    position: relative;
    top: 5px;
}

a {
    text-decoration: none;
    color: #1F171F;
}

#give-us-a-call {
    font-size: 16px;
    font-family: "PT Sans";
    font-style: italic;
}

.fab-phone-circle {
    display: none;
}

.fab-phone-extended>button {
    background-color: var(--bg-secondary);
}

.fab-phone-extended>button:hover {
    background-color: #ffa32b;
}

@media only screen and (max-width: 1000px) {
    .fab-phone-extended {
        position: absolute;
        right: 5%;
    }
}

@media only screen and (max-width: 700px) {
    .fab-phone-extended {
        display: none;
    }

    .fab-phone-circle {
        display: block;
        position: absolute;
        z-index: 1001;
        right: 10%;
        top: 26px;
    }

    .fab-phone-circle>button>a>svg {
        position: relative;
        top: 5px;
    }

    .fab-phone-circle>button {
        background-color: var(--bg-secondary);
    }

    .fab-phone-circle>button:hover {
        background-color: #ffa32b;
    }
}

@media only screen and (max-width: 350px) {
    .fab-phone-circle {
        right: 2%;
    }
}