.sub-grid-select > h3 {
  padding-bottom: 5px;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}

.sub-grid-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 8px;
}