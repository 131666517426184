.InformationPage {
    background-color: var(--bg-color-gray);
}

.InformationPage-Container {
    display: grid;
    grid-template-rows: repeat(3, 250px);
    width: 60vw;
    margin-left: 19vw;
    margin-right: 19vw;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 125px;
    background-color: var(--bg-color-white);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.InformationPage-Credits > ul > li {
    padding: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: medium;
    text-decoration: underline;
    color: blue;
}

h2 {
    text-decoration: underline;
text-decoration-color: rgba(255, 166, 0, 0.75);
}

p {
    font-size: 25px;
}

.InfoPageTitle {
    padding-bottom: 10px;
}

@media only screen and (max-width: 2045px) {
    p {
        font-size: 24px;
    }
}
@media only screen and (max-width: 1665px) {
    p {
        font-size: 22px;
    }
}
@media only screen and (max-width: 1515px) {
    p {
        font-size: 21px;
    }
}
@media only screen and (max-width: 1300px) {
    .InformationPage-Container {
        width: 80vw;
        margin-left: 9vw;
        margin-right: 9vw;
    }
    
    p {
        font-size: 23px;
    }
}
@media only screen and (max-width: 1175px) {
    p {
        font-size: 21px;
    }
}
@media only screen and (max-width: 1000px) {
    .InformationPage {
        background-color: var(--bg-color-white);
    }
    .InformationPage-Container {
        box-shadow: none;
    }
}
@media only screen and (max-width: 980px) {
    .InformationPage-Container {
        grid-template-rows: repeat(3, 300px);
    }
}
@media only screen and (max-width: 795px) {
    .InformationPage-Container {
        grid-template-rows: repeat(3, 365px);
    }
}
@media only screen and (max-width: 630px) {
    .InformationPage-Container {
        grid-template-rows: repeat(3, 360px);
    }
    .InformationPage-Credits > ul > li {
        padding: 3px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: medium;
    }
}
@media only screen and (max-width: 555px) {
    .InformationPage-Container {
        grid-template-rows: repeat(3, 400px);
    }
}
@media only screen and (max-width: 500px) {
    .InformationPage-Container {
        grid-template-rows: repeat(3, 455px);
    }
    .InformationPage-Credits > ul > li {
        padding: 2px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: small;
    }
}
@media only screen and (max-width: 440px) {
    .InformationPage-Container {
        grid-template-rows: repeat(3, 325px);
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
    }
    p {
        font-size: 18px;
    }
}
@media only screen and (max-width: 400px) {
    .InformationPage-Container {
        grid-template-rows: repeat(3, 375px);
    }
}
