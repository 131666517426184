.contact-form-title {
    padding-top: 140px;
    display: flex;
    justify-content: center;
    color: black;
    background-color: var(--bg-color-gray);
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.center-div {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    background-color: var(--bg-color-gray);
}

.contact-form {
    display: flex;
    flex-direction: column;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding: 32px;
    margin-bottom: 11.2vh;
    width: 800px;
    height: 550px;
    background-color: var(--bg-color-white);
}

.contact-information-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 80px;
    justify-items: center;
}

.contact-information-grid>fieldset {
    display: flex;
    flex-direction: column;
    width: 85%;
    padding-top: 12.8px;
}

.section-header {
    text-decoration: underline;
    text-decoration-color: rgba(255, 166, 0, 0.75);
}

.address-information-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 80px;
    justify-items: center;
}

.address-information-grid>fieldset {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding-top: 12.8px;
}

.appliance-information-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 80px;
    justify-items: center;
}

.appliance-information-grid>fieldset {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.appliance-category-header {
    position: relative;
    top: 6.4px;
    left: 11.2px;
    font-size: 13px;
    font-weight: 400;
    margin-left: 24px;
    color: rgba(0, 0, 0, 0.54);
}

.appliance-warranty-header {
    position: relative;
    top: 9.6px;
    left: -4.8px;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);
}

.appliance-warranty-flex {
    grid-row: 4;
    width: 175%;
    padding-top: 4.8px;
}

.appliance-warranty-flex>input {
    margin-right: 12.8px;
    align-items: center;
}

.radio-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 24px 24px 24px 24px;
    width: 320px;
    padding-top: 4.8px;
    z-index: 100;
}

.radio-grid-item {
    display: flex;
    padding-top: 8px;
    position: relative;
    left: -4.8px;
    align-items: center;
}

.radio-grid-item>input {
    margin-right: 16px;
}

input[type="radio"],
input[type="checkbox"] {
    width: 18px;
    height: 18px;
}

.problem-input {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1;
    grid-auto-rows: 80px;
    justify-items: center;
}

.problem-input > fieldset {
    width: 95%;
}

.problem-input > fieldset > div {
    width: 100%;
}

.submit-button {
    position: relative;
    top: 40px;
    align-self: center;
    width: 400px;
    height: 60px;
}

.submit-button:hover {
    color: var(--fc-secondary);
}

@media only screen and (max-width: 1000px) {
    .contact-form {
        height: auto;
        margin-bottom: 160px;
        box-shadow: none;
        padding-top: 0;
    }
    .contact-form-title {
        background-color: var(--bg-color-white);
    }
    
    .center-div {
        background-color: var(--bg-color-white);
    }
} 

@media only screen and (max-width: 930px) {
    .contact-form {
        height: auto;
        width: 100%;
        margin-bottom: 160px;
    }

    .contact-information-grid {
        grid-template-columns: 1fr;
        justify-items: start;
    }

    .contact-form-title {
        padding-top: 125px;
    }

    .contact-information-grid>fieldset {
        width: 90%;
    }

    .radio-grid {
        position: relative;
        left: 15px;
    }

    .address-information-grid {
        grid-template-columns: 1fr;
        justify-items: start;
    }

    .appliance-information-grid {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        justify-items: start;
    }

    .appliance-category-header {
        left: -15px;
    }

    .appliance-details {
        margin-top: 15px;
        grid-column: 1;
    }

    .problem-input {
        margin-top: -80px;
        justify-items: start;
    }
    
    .problem-input > fieldset {
        width: 90%;
    }

    .submit-button {
        top: 58px;
        width: 90%;
    }
}

@media only screen and (max-width: 500px) {
    .appliance-information-grid {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr .8fr .8fr;
        justify-items: start;
    }

    .radio-grid {
        grid-template-columns: 1fr;
        grid-template-rows: 30px 30px 30px 30px 30px 30px;
    }

    .radio-grid-item,
    .appliance-warranty-flex>label {
        font-size: 22px;
    }

    input[type="radio"],
    input[type="checkbox"] {
        width: 28px;
        height: 28px;
    }

    .appliance-warranty-flex {
        width: 100%;
        grid-row: 6;
        padding-top: 4.8px;
    }

    .appliance-details {
        margin-top: 38px;
    }

    
    .problem-input {
        margin-top: -120px;
    }

    .submit-button {
        top: 25px;
        width: 90%;
    }
}

@media only screen and (max-width: 390px) {
    .center-div {
        width: auto;
        margin: 0;
    }
    .contact-form {
        width: 80%;
        padding: 0px;
    }
    .radio-grid {
        width: 260px;
    }
}