.main-page {
    display: grid;
    background-color: var(--bg-color-white);
    grid-template-columns: .75fr 2fr 2fr 2fr 2fr .75fr;
    grid-template-rows: 100px auto 1fr;
}

.image-div {
    background-image: linear-gradient(to bottom right, rgba(31, 31, 31, 0.3), rgba(0, 0, 0, 0.6)),
        url(../../img/cleanKitchenBig.jpg);
    background-size: cover;
    background-position: center 60%;
    height: 350px;
    grid-row: 2;
    grid-column: 1/7;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image-div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
}

.image-div > div > h2 {
    width: 40%;
    transform: translate(-30%, 0%);
}

.image-div > div > button {
    margin-top: 75px;
    transform: translate(-60%, 0%);
}

.subgrid-div {
    grid-row: 3;
    grid-column: 4/6;
    margin-left: 15px;
    margin-bottom: 30px;
}

.map-div {
    grid-row: 3;
    grid-column: 2/4;
    margin-right: 15px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media(max-width: 1300px) {
    .image-div > div > h2 {
        width: 80%;
        transform: translate(0%, 0%);
    }

    .image-div > div > button {
        transform: translate(0%, 0%);
        margin-top: 35px;
        width: 40% !important;
    }
}

@media(max-width: 850px) {
    .image-div {
        background-image: linear-gradient(to bottom right, rgba(31, 31, 31, 0.3), rgba(0, 0, 0, 0.6)),
        url(../../img/cleanKitchen.jpg);
        background-size: cover;
        background-position: center 35%;
    }

    .main-page {
        display: grid;
        grid-template-columns: 2fr;
        grid-template-rows: 100px auto 650px 650px;
        overflow: hidden;
    }

    .subgrid-div {
        grid-column: 1;
        margin-left: 0;
        padding: 15px;
        margin-bottom: 0;
    }
    
    .map-div {
        grid-column: 1;
        grid-row: 4;
        margin-right: 0;
        padding: 15px;
    }
}

@media(max-width: 700px) {
    .image-div > div > h2 {
        width: 95%;
    }
}

@media(max-width: 600px) {
    .image-div > div > button {
        width: 70% !important;
    }
}

@media(max-width: 515px) {
    .main-page {
        grid-template-rows: 100px auto 535px 650px;
    }
}

@media(max-width: 415px) {
    .main-page {
        grid-template-rows: 100px auto 500px 650px;
    }
}
/* 
@media(max-width: 415px) {
    .main-page {
    }
} */
