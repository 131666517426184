.modal-box {
width: 25%;
border-radius: 5px;
}

.attention {
display: flex;
justify-content: center;
font-family: "PT Sans";
font-size: 30px;
margin-bottom: 10px;
}

.modal-content {
font-family: "Nunito";
font-size: 18px;
text-align: justify;
}

@media only screen and (max-width: 1600px) {
.modal-box {
width: 40%;
}
}
@media only screen and (max-width: 1100px) {
.modal-box {
width: 50%;
}
}
@media only screen and (max-width: 900px) {
.modal-box {
width: 70%;
}
}
@media only screen and (max-width: 600px) {
.modal-box {
width: 88%;
}
}