@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

#copy-notification {
    position: absolute;
    width: auto;
    height: auto;
    background-color: rgba(255, 145, 0, 0.9);
    pointer-events: none;
    animation: fadeIn;
    animation-duration: 1.8s;
    border-radius: 3px;
    padding: 6px;
    font-size: 20px;
}


footer {
    background-color: var(--bg-color-blue);
    box-shadow: 0px -5px 5px #00006685;
}

.info-footer {
    display: grid;
    width: 60vw;
    margin-left: 19vw;
    margin-right: 19vw;
    padding: 1vw;
    grid-template-columns: 1fr 1fr;
    height: 160px;
    justify-items: center;
    color: var(--fc-secondary);
}

.info-footer>div:nth-of-type(1)>ul>li {
    padding-top: 5px;
}

.info-footer>div:nth-of-type(2)>ul>li {
    padding-top: 13px;
}

.footer-p {
    text-justify: auto;
    font-size: 16px;
    color: var(--fc-secondary);
}

.attributions {
    grid-row: 2;
    grid-column: 1/3;
    font-size: 12px;
}

.attributions>a {
    color: var(--fc-secondary);
}

.footer-break {
    display: none;
}

.information-links>li {
    cursor: pointer;
    color: white;
}

.information-links>li:first-child {
    cursor: auto;
}

#emailcopy-popup {
    position: absolute;
    display: block;
    transform: translate(250%, 0%);
    background-color: var(--bg-color-gray);
    color: #1F171F;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 999; /* optional: set a higher z-index to make sure the popup appears on top of other elements */
  }
@media only screen and (max-width: 1300px) {
    .info-footer {
        width: 80vw;
        margin-left: 9vw;
        margin-right: 9vw;
        height: 90px;
        padding-bottom: 60px;
    }
}

@media only screen and (max-width: 1000px) {
    .footer-break {
        display: block;
    }

    .info-footer {
        width: 35vw;
        margin-left: 31.5vw;
        margin-right: 31.5vw;
        padding-bottom: 250px;
        grid-template-columns: 1fr;
        justify-items: flex-start;
    }

    .attributions {
        grid-row: 3;
        grid-column: 1;
        font-size: 10px;
    }
}

@media only screen and (max-width: 930px) {
    .info-footer {
        width: 40vw;
        margin-left: 29vw;
        margin-right: 29vw;
    }
}

@media only screen and (max-width: 815px) {
    .info-footer {
        width: 50vw;
        margin-left: 24vw;
        margin-right: 24vw;
    }
}

@media only screen and (max-width: 650px) {
    .info-footer {
        width: 60vw;
        margin-left: 19vw;
        margin-right: 19vw;
    }
}

@media only screen and (max-width: 550px) {
    .info-footer {
        width: 70vw;
        margin-left: 14vw;
        margin-right: 14vw;
    }
}

@media only screen and (max-width: 465px) {
    .info-footer {
        width: 85vw;
        margin-left: 6.5vw;
        margin-right: 6.5vw;
    }
}

@media only screen and (max-width: 450px) {
    .info-footer {
        padding-bottom: 270px;
    }

    .attributions {
        font-size: 10px;
    }
}

@media only screen and (max-width: 430px) {
    .info-footer {
        font-size: 14px;
    }
}