.sub-grid-1-item {
  padding: 16px;
  border: 1px solid #1F171F;
  border-radius: 5px;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 3px 3px rgba(0, 0, 0, 0.15);
}

img {
width: 160px;
  margin-bottom: 16px
}

.sub-grid-1-button {
  width: 90%;
}
.sub-grid-1-button:hover {
  color: var(--fc-secondary-hover);
}
@media only screen and (max-width: 515px) {
  img {
    width: 120px;
    margin-bottom: 16px
  }
}

@media only screen and (max-width: 415px) {
  img {
    width: 100px;
    margin-bottom: 16px
  }
}