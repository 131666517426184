#google-map {
    border: solid 1px #1F171F;
    border-radius: 3px;
    box-shadow: 3px 3px rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 480;
}

@media(max-width: 1000px) {
  #google-map {
    position: static;
  }
}